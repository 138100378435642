import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { TabBarDataItems } from '@/src/components/UI/Footer.mobile';

const useShowMobileFooter = () => {
  const router = useRouter();

  const [showFooter, setShowFooter] = useState(false);
  const [activeHref, setActiveHref] = useState<string>(router.asPath);

  useEffect(() => {
    const paths = TabBarDataItems.map((item) => item.href);
    setShowFooter(paths.indexOf(router.pathname) !== -1);
    setActiveHref(router.asPath);
  }, [router]);

  return { showFooter, activeHref };
};

export default useShowMobileFooter;
